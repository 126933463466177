export enum LocalStorageKey {
    RoomCount = 'playback_room_count',
    Uid = 'playback_anon_id',
    StreamVolume = 'playback_stream_volume',
    StageVolume = 'playback_stage_volume',
    PinnedMessages = 'playback_pinned_messages',
    ChatNotificationsDisabled = 'playback_chat_notifications_disabled',
    SupportEnabled = 'playback_support_enabled',
    MessagesPane = 'playback_messages_pane',
    MessagesOpen = 'playback_messages_open',
    TvAuthConnections = 'playback_tv_auth_connections',
    LastAuthMethod = 'playback_last_auth_method',
}

export const localStorageGet = (key: LocalStorageKey | string, defaultValue?: any) => {
    const value = localStorage.getItem(key);

    if (!value) {
        return defaultValue;
    }

    try {
        return JSON.parse(value);
    } catch (e) {
        return value;
    }
};

export const localStorageSet = (key: LocalStorageKey | string, value: any) => {
    const json = typeof value === 'string' ? value : JSON.stringify(value);
    localStorage.setItem(key, json);
};

export const localStorageDelete = (key: LocalStorageKey | string) => {
    localStorage.removeItem(key);
};
